import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./imageCarousel.css";

export function Example(props) {
  let items = [
    {
      image:
        "https://images.unsplash.com/photo-1494452672938-ad7f60625bb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1446776899648-aa78eefe8ed0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1517181875630-f72350452109?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    },
    {
      image:
        "https://www.sunbirddcim.com/sites/default/files/AdobeStock_250694244.jpg",
    },
  ];

  return (
    <Carousel autoPlay={true} duration={350} stopAutoPlayOnHover={false}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <div id={"home"}>
      <img className={"imagesCarousel"} src={props.item.image} />
      <div id={"home"} className="topBoxContainer">
        <Grid container spacing={0}>
          <Grid item xs={4} sm={4}>
            <div class={"greenBox1"}>
              <div>
                LEVERANDØR AV FIBEROPTIKK, TELECOM, PARABOL, ANTENNE OG KABEL-TV
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className={"greyBox"}>
              <div>
                UTFØRER ARBEID BÅDE FOR PRIVATE OG BEDRIFTER
              </div>
            </div>
          </Grid>

          <Grid item xs={4} sm={4}>
            <div class={"greenBox2"}>
              <div>
                BRED KUNNSKAP INNEN 5G, PARABOL, ANTENNETEKNIKK, IPTV, FIBERNETT, CAT-5/6 NETT OG UTBYGGING AV BASESTASJONER
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
