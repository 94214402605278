import Grid from "@mui/material/Grid";
import "./services.css";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import React from "react";

export function Services() {
  return (
    <div class={"services"} id={"services"}>
      <Container style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={12}>
            <div className={"topTextInService"}>
              <Typography
                style={{ marginTop: 25, marginBottom: 25 }}
                variant={"h3"}
              >
                Her er noen av de tjenestene vi utfører
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Fiberoptikk, montering, skjøting og prosjektering ( vi har skjøtemaskiner og måleinstrumenter / OTDR )
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Vi bygger basestasjoner for telecomleverandører, med fokus på presisjon og kvalitet
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Justering av parabol og antenne digitalt bakkenett (RiksTV)
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Oppgradering/ utvidelse av parabol og antenneanlegg
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Oppgradering av kabel-tv anlegg
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Feilsøking antenne (RiksTV), parabol og kabel-tv
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Ny montering for Canal Digital, Viasat og RiksTV (bedrift og privat)
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
          <div className={"servicesBoxes"}>
            <Typography variant="h5">
              Montering av parabol for mottak av utenlandske kanaler, eksempelvis Astra og Hotbird.
            </Typography>
          </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                IPTV, prosjektering, montering og drift
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
          <div className={"servicesBoxes"}>
            <Typography variant="h5">
              Infosystemer, salg, montering og prosjektering
            </Typography>
          </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
