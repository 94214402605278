import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
  color: "black";
  &:hover {
    color: #8cc540;
  }
  &:focus {
  }
`;
