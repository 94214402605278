import React from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";

export function About() {
  return (
    <Container maxWidth={"lg"} style={{ marginTop: 50 }} id={"about"}>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant={"h3"} style={{ color: "#696969" }}>
            Velkommen til Flexcom
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={5} marginTop={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={"p"} style={{ color: "grey" }}>
            Vårt satsningsområde er å være en komplett leverandør av alt innen fiberoptikk,
            telecom, parabol, antenne og kabel-TV, for små og store-nett og frittstående brukere.
            Vi er lokaliser sentralt i Oslo, med flere servicebiler som dekker Oslo  og Viken omegn.
            Vi har EKOMNETT-AUTORISASJON  ENA autoriserings nr: 8856
            Vi utfører arbeid både for private og bedrifter.
            Vi har meget stor kunnskap og erfaring innen telecom, 5G, parabol,
            antenneteknikk, IPTV, fibernett, cat-5/6 nett og bygging av basestasjoner for mobilkommunikasjon.

          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant={"p"} style={{ color: "grey" }}>
            Mange av våre ansatte har vært i bransjen i over 20 år, så her lover vi en meget høy faglig kompetanse.
            Flexcom AS har også et meget tett samarbeid med montører og forhandlere over hele landet.
            Så trenger du hjelp med fiberoptikk, antenne, parabol eller planlegger du et kabel-tv nett og
            ønsker en samarbeidspartner, så ta gjerne kontakt med oss for en hyggelig prat.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} marginTop={8}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ textAlign: "center", color: "#696969" }}
        >
          <Typography variant={"h4"}>
            Montering og service
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} marginTop={4} marginBottom={8}>
        <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ textAlign: "left", color: "#696969" }}
        >
          <Container maxWidth={"md"}>
          <Typography variant={"p"}>
            Vi har serviceteknikere som utfører alt fra små oppdrag hjemme hos deg, til større installasjoner og feilrettinger hos din bedrift, borettslag eller institusjon.
            De fleste ansatte har vært i bransjen i over 20 år og har stor kunskap og kompetanse innen faget.
            Tv-verdnen har de siste 20 årene hatt en stor utvikling. Dette medfører også at man må oppgradere og vedlikeholde tilhørende kabler og antenneutstyr. En service behøver ikke alltid å bety utskiftning av kabler og lignende, men kanskje bare en enkel justering av din antenne.

          </Typography>
          </Container>
        </Grid>
      </Grid>
      <Grid container spacing={0} marginTop={3}>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <Typography
            variant={"h3"}
            style={{ color: "#696969" }}
            display={{ xs: "none", md: "block" }}
          >
            FIBEROPTIKK – TELECOM – ANTENNETEKNIKK
          </Typography>
          <Typography
            variant={"h3"}
            style={{ color: "#696969" }}
            display={{ xs: "block", md: "none" }}
          >
            FIBEROPTIKK
            <br />
            TELECOM
            <br />
            ANTENNETEKNIKK
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
