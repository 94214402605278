import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import "./navbar.css";
import { StyledButton } from "../styledBtn";
import { HashLink, HashLink as Link } from "react-router-hash-link";
import { Link } from "react-router-dom";

const pages = [
  {
    page: "Hjem",
    link: "home",
  },
  {
    page: "Om oss",
    link: "about",
  },
  {
    page: "Tjenester",
    link: "services",
  },
  {
    page: "Kontakt",
    link: "contact",
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position={"relative"}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            className="logo"
            src={require("../../images/flexcomremovebgpreview.png")}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              style={{ marginLeft: "auto" }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.page} onClick={handleCloseNavMenu}>
                  <HashLink
                    smooth
                    to={`pages/homePage/${page.link}.jsx#${page.link}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Typography variant="p" textAlign="center">
                      {page.page}
                    </Typography>
                  </HashLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <HashLink
                smooth
                to={`pages/homePage/${page.link}.jsx#${page.link}`}
                style={{ textDecoration: "none" }}
              >
                <StyledButton
                  style={{ textTransform: "none" }}
                  key={page.page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  {page.page}
                </StyledButton>
              </HashLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
