import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./src/styling/theme";
import { Home } from "./src/pages/homePage/home";
import Navbar from "./src/components/navbar/navbar";
import { About } from "./src/pages/homePage/about";
import { Example } from "./src/components/imageCarousel/imageCarousel";
import { Services } from "./src/pages/homePage/services";
import { Contact } from "./src/pages/homePage/contact";
import React from "react";

export function App(props) {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Example />
        <About />
        <Services />
        <div
          style={{ width: "100%", height: 20, backgroundColor: "#8CC540" }}
        ></div>
        <Contact />
        <Routes>
          <Route path={"/"} element={<Home />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}
